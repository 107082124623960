import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Button, Columns, Container, Form, Heading, Icon, Section } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { useSession, useSessionRequest } from '../stores/session'
import { RequestMessage } from '../stores/utils'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ResetPasswordPage = () => {
  const [email, setEmail] = React.useState('')
  const [state, actions] = useSession()
  const [request] = useSessionRequest({
    key: 'post /users/password-recovery'
  })

  const submitForm = React.useCallback((e) => {
    e.preventDefault()
    actions.requestNewPassword({ email })
  }, [email])

  const { t } = useTranslation()

  return (
    <Layout>
      
      <Section size='medium'>
        <Container>
          <Columns centered gap={8}>
            <Columns.Column size={6}>
              <Heading size={5}>
                {t('reset-password:title')}
              </Heading>
              <RequestMessage
                request={ request }
                messages={{
                  success: <span>
                    {t('reset-password:confirmation')} <strong>{ email }</strong>.
                  </span>
                }}
              />
              {
                (!request || request.status !== 'success') &&
                  <form onSubmit={submitForm}>
                    <Form.Field>
                      <Form.Label>
                        {t('forms:contact.email')}
                      </Form.Label>
                      <Form.Control>
                        <Icon align='left'>
                          <FontAwesomeIcon icon={faUser} />
                        </Icon>
                        <Form.Input
                          value={email}
                          onChange={ (e) => setEmail(e.target.value) }
                          type='email'
                        />
                      </Form.Control>
                      <Form.Help>
                        {t('reset-password:input-info')}
                      </Form.Help>
                    </Form.Field>
                    <Form.Field mt={5}>
                      <Form.Control>
                        <Button
                          type='submit'
                          color='primary'
                          disabled={ request && request.status === 'in_progress' }
                        >
                          {t('reset-password:submit')}
                        </Button>
                      </Form.Control>
                    </Form.Field>
                  </form>
              }
            </Columns.Column>
          </Columns>
        </Container>
      </Section >
    </Layout >
  )
}

export default ResetPasswordPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`